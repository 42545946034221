import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import SinglePropertyDetails from "../../../singlePropertyDetails/singlePropertyDetails";
import "./viewDetailsModal.css";
//----------------------------------------------------------------------------------------------------//
const ViewDetailsModal = ({ property }) => {
  const [currentFloorNumber, setCurrentFloorNumber] = useState(0);
  //------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------//
  function getProjectTypeName(type) {
    switch (type) {
      case "mall":
        return "المول";
      case "compound":
        return "الكمبوند";
      default:
        return "";
    }
  }
  //------------------------------------------------------------------------------------------//
  useEffect(() => {
    const myModalEl = document.getElementById("view-details-modal");
    myModalEl.addEventListener("hidden.bs.modal", function (event) {
      setCurrentFloorNumber(0);
    });
    return () => {
      const myModalEl = document.getElementsByClassName("modal-backdrop")[0];
      const body = document.getElementsByTagName("body")[0];
      if (myModalEl) {
        myModalEl.remove();
      }
      if (body) {
        body.classList = [];
        body.style = "";
      }
    };
  }, []);
  //------------------------------------------------------------------------------------------//
  return (
    <div id="view-details-modal" className="modal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog view-details-modal-dialog">
        <div className="modal-content view-details-modal-content">
          <div className={english ? "modal-header view-details-modal-header" : "modal-header view-details-modal-header-arabic"}>
            {property !== undefined &&
              (english ? (
                <h3 className="text-darkblue">{property.projectType[0].toUpperCase() + property.projectType.slice(1)} Full Details</h3>
              ) : (
                <h3 className="text-darkblue">تفاصيل عن {getProjectTypeName(property.projectType)}</h3>
              ))}
            <button
              type="button"
              className={english ? "btn-close" : "btn-close btn-close-arabic"}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {property !== undefined && (
            <div className="modal-body">
              <div className="property-details-wrapper">
                <div className="property-details-left">
                  <img src={property.propertyCAD[currentFloorNumber].CAD} alt=""></img>
                  <div className="property-details-left-floor-slider">
                    <button
                      className="btn btn-link"
                      disabled={currentFloorNumber === 0 ? true : false}
                      onClick={() => {
                        if (currentFloorNumber > 0) {
                          setCurrentFloorNumber(currentFloorNumber - 1);
                        }
                      }}
                    >
                      <i className="fa fa-chevron-left me-1"></i>
                      {english ? "Previous floor" : "الطابق السابق"}
                    </button>
                    <button
                      className="btn btn-link"
                      disabled={currentFloorNumber === property.propertyCAD.length - 1 ? true : false}
                      onClick={() => {
                        if (currentFloorNumber < property.propertyCAD.length - 1) {
                          setCurrentFloorNumber(currentFloorNumber + 1);
                        }
                      }}
                    >
                      {english ? "Next floor" : "الطابق التالي"}
                      <i className="fa fa-chevron-right ms-1"></i>
                    </button>
                  </div>
                </div>
                <SinglePropertyDetails property={property} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsModal;
